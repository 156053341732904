import {request} from "../network";
import store from '@/store';

export function getSignature(index) {
  return request({
    method: 'get',
    url: `/web/download/getSignature/${index}`,
  })
}

//cos签名-web直传 1-个人信息上传2-作业上传3-银行缴费截图上传
export function cosSignature(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/download/getSignature',
    data
  })
}

export function achievementCallBack(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/notify/achievementCallBack',
    data
  })
}
